


















































































































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import KeyElementsEdit from '@bertazzoni/back/components/KeyElementsEdit.vue'
import UploadFile from '@bertazzoni/back/components/UploadFile.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import {
  FileTypeFormat,
  VideoFormat,
  PptFormat
} from '@bertazzoni/common/src/helpers/utils/fileManager'
import { Content, UpdateContentDto, TypeContent } from '@bertazzoni/common/src/models/content.model'
import FormError from '@bertazzoni/common/src/components/FormError.vue'

@Component({
  components: { Loader, FormError, Icons, UploadFile, DatePicker, KeyElementsEdit }
})
export default class UpdateContent extends Vue {
  private currentContent: UpdateContentDto = new UpdateContentDto()
  private fullContent: Content = new Content()
  private pageLoaded = false
  private contentLoad = false
  private imageLoad = false
  private resetUploaders = false
  private submitLoading = false
  private formatFile = FileTypeFormat
  private languageList: string[] = []

  async created(): Promise<void> {
    this.fullContent = await ContentService.findOne(this.$route.params.id)
    this.languageList = await getLanguageList(false)
    this.resetContent()
    this.fullContent = JSON.parse(JSON.stringify(this.currentContent))
    if (this.currentContent.type === TypeContent.ppt) {
      this.currentContent.srcURI = this.currentContent.srcURI.split("'")[1]
    }
    this.pageLoaded = true
  }
  contentUpload(loading: boolean): void {
    this.contentLoad = loading
  }
  imageUpload(loading: boolean): void {
    this.imageLoad = loading
  }
  getDate(newDate: Date): void {
    this.currentContent.expirationDate = newDate ? new Date(newDate) : newDate
  }
  getImage(newImage: string): void {
    this.currentContent.previewImageURI =
      newImage !== '' ? newImage : this.fullContent.previewImageURI
  }
  setKeyElement(newKeyElement: string[]): void {
    this.currentContent.keyElements = newKeyElement
  }
  getContent(newContent: string): void {
    this.currentContent.srcURI = newContent !== '' ? newContent : this.fullContent.srcURI
  }
  getTypeContent(format: string): void {
    if (format in VideoFormat) {
      this.currentContent.type = TypeContent.video
    } else if (format in PptFormat) {
      this.currentContent.type = TypeContent.ppt
    } else {
      this.currentContent.type = TypeContent.pdf
    }
  }
  resetContent() {
    for (const key in this.currentContent) {
      this.currentContent[key] = this.fullContent[key]
        ? JSON.parse(JSON.stringify(this.fullContent[key]))
        : null
    }
    this.resetUploaders = !this.resetUploaders
  }
  async checkErrors(): Promise<void> {
    const isValid = await this.$validator.validateAll()
    if (isValid) {
      if (this.currentContent !== this.fullContent) {
        if (
          this.currentContent.keyElements &&
          (this.currentContent.keyElements.length === 0 ||
            (this.currentContent.keyElements.length === 1 &&
              this.currentContent.keyElements[0] === ''))
        )
          delete this.currentContent.keyElements
        for (const key in this.currentContent) {
          if (JSON.stringify(this.currentContent[key]) !== JSON.stringify(this.fullContent[key])) {
            this.submitChange()
            return
          }
        }
      }
    }
  }
  async submitChange(): Promise<void> {
    this.changeLoading()
    const sendedContent = this.getSendedContent()
    try {
      await ContentService.updateOne(sendedContent, this.$route.params.id)
      this.changeLoading()
      this.$router.push('/content-list')
    } catch (e) {
      this.changeLoading()
    }
  }
  getSendedContent(): UpdateContentDto {
    const sendedContent: UpdateContentDto = new UpdateContentDto(this.currentContent.type)
    for (const key in this.currentContent) {
      if (JSON.stringify(this.currentContent[key]) !== JSON.stringify(this.fullContent[key])) {
        sendedContent[key] = this.currentContent[key]
      }
    }
    if (this.currentContent.language !== this.fullContent.language) {
      sendedContent.language = this.currentContent.language
    }
    if (sendedContent.expirationDate) {
      sendedContent.expirationDate.toString()
    }
    return sendedContent
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
